<template>
  <div class="home">
    <div class="header_top space_between">
      <handleback></handleback>
      <div class="top_title">{{ title }}</div>
      <div>{{ $t('h.gameplayDescription') }}</div>
    </div>

    <div class="main" v-if="type == 'yhxy'">
      <div class="main_tab space_around">
        <div class="btn center" :class="key == 1 ? 'active' : ''" @click.stop="handlechange(1); playAudio()">
          <div class="ic">{{ $t('h.prvacyPolicy') }}</div>
        </div>
        <div class="btn center" :class="key == 2 ? 'active' : ''" @click.stop="handlechange(2); playAudio()">
          <div class="ic">{{ $t('h.userAgreement') }}</div>
        </div>
        <div @mousedown="startDrag" class="slider" ref="slider" :style="{ left: left + 'px' }"></div>
      </div>
      <div class="main_content" ref="content" v-html="contents"></div>
    </div>
    <div class="main" v-if="type == 'bzzx'">
      <div class="space_between main_ii_top">
        <div class="center main_ii_top_left" @click="isselect = true">
          {{ titleqqq }}
          <i class="el-icon-arrow-right"></i>
          <div class="main_ii_top_left_xl" v-if="isselect">
            <div @click.stop="handlehelptype(item.value)" class="main_ii_top_left_xl_btn center"
              v-for="(item, index) in helptype" :key="index">
              {{ item.type }}
            </div>
          </div>
        </div>
      </div>
      <div class="main_ii_center">
        <div ref="bangzhuzhongxin" v-html="contents"></div>
      </div>
    </div>
    <div class="main" v-if="type == 'gywm'">
      <div class="main_iii">
        <div class=" mian_iii_center">
          <div v-for="(item, index) in articleList" :key="index">
            <div v-if="item.id != 2 && item.id != 5" class="mian_iii_center-name" @click="dianji(item.id)"><span>{{item.title}}</span>
              <svg t="1720492291090" class="icon" viewBox="0 0 1024 1024" version="1.1"
                xmlns="http://www.w3.org/2000/svg" p-id="4257" width="26" height="26">
                <path
                  d="M917.284001 297.722752c-5.406127-5.437849-13.06762-8.396227-21.479197-8.396227-9.611915 0-18.953677 3.844561-25.713638 10.543124L511.980046 659.992589 153.873018 299.91672c-6.729262-6.745634-16.072047-10.619872-25.654286-10.619872-8.470929 0-16.131399 2.989077-21.598924 8.457626-12.301164 12.435217-11.32493 33.69031 2.192945 47.312562l376.764969 378.821815c6.758937 6.788613 15.860223 10.723226 25.052582 10.8143l3.425006 0c8.981559-0.301875 17.814738-4.205788 24.423249-10.8143l376.733247-378.852514C928.728658 331.382363 929.690566 310.113967 917.284001 297.722752"
                  fill="#bfbfbf" p-id="4258"></path>
              </svg>
            </div>
            <transition name="el-zoom-in-top">  
               <div v-if="item.id === isshow" class="main_content" v-html="item.content"></div>
              </transition>
         
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getagreement, getArticle } from "@/api/index";
export default {
  data() {
    return {
      contents: "",
      isselect: false,
      helptype: [

        {
          type: this.$i18n.t('h.retrieveTutorial'),
          value: 1,
        }

      ],
      titleqqq: this.$i18n.t('h.websiteRechargeTutorial'),
      dragging: false,
      startX: 0,
      left: 4,
      key: 2,
      title: "",
      type: null,
      data: {
        about: {
          qr: [
            // {
            //   url: "https://img2.baidu.com/it/u=3169331048,4117927422&fm=253&fmt=auto&app=138&f=GIF?w=200&h=200",
            //   name: "QQ客服",
            // },
            // {
            //   url: "https://img1.baidu.com/it/u=2426361314,145551708&fm=253&fmt=auto&app=138&f=GIF?w=200&h=200",
            //   name: "QQ2群",
            // },
            {
              url: "123.png",
              name: "QQ1群",
            },
          ],
          // service: [
          //   {
          //     type: "客服热线：",
          //     value: "12345456",
          //   },
          //   {
          //     type: "客服热线：",
          //     value: "12345456",
          //   },
          //   {
          //     type: "客服热线：",
          //     value: "12345456",
          //   },
          //   {
          //     type: "客服热线：",
          //     value: "12345456",
          //   },
          // ],
        },
      },
      articleList: [],
      isshow: -1,
      queryId:''
    };
  },
  mounted() {
   
    this.gettype();
    this.getArticle()
   
   
  },
  methods: {
    dianji(e) {
      if(e==this.isshow){
        this.isshow=-1

      }else{
        this.isshow = e
      }
     
    },
    getArticle() {
      getArticle("sydb").then((res) => {
        this.articleList = res.data.data.contentList;
  
      });
    },
    playAudio() {
      this.$store.commit("playAudio");
    },
    handlehelptype(res) {
      this.titleqqq = this.helptype[res - 1].type;
      this.isselect = false;
      this.getlist(4);
    },
    handlechange(res) {
      this.key = res;
      //   document.getElementsByClassName('main_content').
      const width = this.$refs.slider.offsetWidth;
      this.left = width * (res - 1);
      if (res == 1) {
        this.getlist(2);
      } else if (res == 2) {
        this.getlist(5);
      }
    },
    getlist(stat) {
      getagreement(stat).then((resa) => {
        if (resa.data.code == 200) {
          this.contents = resa.data.data.content;
        }
      });
    },
    startDrag(event) {
      const element = this.$refs.slider;
      element.className = " slider";
      this.dragging = true;
      this.startX = event.clientX - this.left;
      document.addEventListener("mousemove", this.drag);
      document.addEventListener("mouseup", this.endDrag);
    },
    drag(event) {
      if (this.dragging) {
        let newLeft = event.clientX - this.startX;
        if (newLeft < 0) {
          this.left = 4;
        } else if (
          newLeft >
          this.$refs.slider.parentElement.offsetWidth -
          this.$refs.slider.offsetWidth
        ) {
          this.left =
            this.$refs.slider.parentElement.offsetWidth -
            this.$refs.slider.offsetWidth;
        } else {
          this.left = newLeft;
        }
      }
    },
    endDrag() {
      this.dragging = false;
      const width = this.$refs.slider.offsetWidth;
      if (this.left < width * 0.75) {
        this.left = 4;
        this.key = 1;
      } else {
        this.left = width;
        this.key = 2;
      }
      const element = this.$refs.slider;
      element.classList += " tr";
      document.removeEventListener("mousemove", this.drag);
      document.removeEventListener("mouseup", this.endDrag);
    },
    gettype() {
      this.type = localStorage.getItem("help-type");
      if (this.type == "yhxy") {
        if(this.$i18n.locale == 'en') {
          // this.title = "隐私协议&用户协议";
          this.title = this.$i18n.t('h.prvacyPolicy') + '&' + this.$i18n.t('h.userAgreement')
        } else {
          this.title = "隐私协议&用户协议";
        }
        this.getlist(2);
      } else if (this.type == "bzzx") {
        if(this.$i18n.locale == 'en') {
          this.title = this.$i18n.t('h.support');
        } else {
          this.title = "帮助中心";
        }
        this.getlist(4);
      } else if (this.type == "gywm") {
        if(this.$i18n.locale == 'en') {
          this.title = this.$i18n.t('h.aboutItemTakes');
        } else {
          this.title = "关于我们";
        }
        this.getlist(3);
      }
      
  
     
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  width: 95%;
  margin: 0 auto;
  text-align: left !important;

}

.header_top {
  width: 100%;
  height: 56px;

  div:last-child {
    color: #fff;
    font-size: 12px;
    text-decoration: underline;
    line-height: 1.5;
    opacity: 0;
  }
}

.main {
  margin-top: 14px;
  width: 100%;
}

.main_tab {
  margin: 0 auto;
  width: 260px;
  height: 42px;
  background: #323436;
  border-radius: 70px 70px 70px 70px;
  opacity: 1;
  font-size: 14px;
  color: #727477;
  position: relative;
}

.btn {
  width: 49%;
  height: 35px;
  opacity: 1;

  .ic {
    position: relative;
    z-index: 2;
  }
}

.active {
  color: #fff;
}

.slider {
  width: 49%;
  height: 35px;
  position: absolute;
  background: linear-gradient(-90deg, #3a97e4 0%, #25f484 100%);
  border-radius: 70px 70px 70px 70px;
  z-index: 1;
}

.slider::before {
  content: "";
  /*必须设置content属性*/
  width: 100%;
  display: block;
  height: 100%;
  background: transparent;
  z-index: 3;
}

.tr {
  transition: left 0.2s ease-in-out;
}

.main_content {
  margin-top: 20px;
  padding: 21px;
  font-size: 12px;
  line-height: 1.5;
  width: 100%;
}

.main_ii_top {
  width: 100%;
  margin: 0 auto;
}

.main_ii_center {
  margin-top: 21px;
  font-size: 12px;
  position: relative;
}

.main_iii {
  width: 100%;
  // height: 350px;
  background: #101115;
  border-radius: 14px 14px 14px 14px;
  opacity: 1;
  padding: 15px;
}

.mian_iii_top {
  width: 100%;
}

.mian_iii_top_qr {
  width: 98px;
  //   background-image: url("???");
  height: 98px;
}

.mian_iii_center {
  .mian_iii_center-name {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #585a60;
    opacity: 1;
    box-sizing: border-box;
    padding: 10px 10px;
    margin: 10px 0;
    font-size: 12px;
    svg {
      margin: 0;
    }
  }
}

.main_ii_top_left {
  width: 224px;
  height: 42px;
  background: #323436;
  border-radius: 70px;
  font-size: 12px;
  position: relative;
  z-index: 5;
}

.main_ii_top_left_xl {
  position: absolute;
  width: 100%;
  left: 0;
  top: 45px;

  background: #101115;
}

.main_ii_top_left_xl_btn {
  width: 100%;
  height: 42px;
  opacity: 1;

  &:hover {
    background: linear-gradient(360deg,
        rgba(37, 244, 132, 0.5) 0%,
        rgba(37, 244, 132, 0.25) 100%);
    border: 1px solid #25f484;
  }
}
</style>
